import {Component, Inject, Input, OnInit, Optional, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {map, take, tap} from 'rxjs/operators';
import {PflegerService} from '../../services/pfleger/pfleger.service';
import {NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry} from 'ngx-file-drop';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {AppCfg} from '../../app.config';
import {combineLatest, Observable} from 'rxjs';
import {JwtAuthenticationService} from '@softline/auth';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {FileService} from '../../modules/shared/services/file/file.service';
import {MDBModalRef} from 'ng-uikit-pro-standard';

@Component({
  selector: 'carrer-documents',
  templateUrl: './viewdocuments.page.html',
  styleUrls: ['./viewdocuments.page.scss'],
})

export class ViewDocumentsPage implements OnInit {

  @Input() token?: any;
  authorizationToken: string;

  constructor(
    public modalRef: MDBModalRef,
    public http: HttpClient,
    public authService: JwtAuthenticationService,
    private _fileService: FileService,
  ) {
  }

  onNoClick(): void {
    this.modalRef.hide();
  }

  ngOnInit(): void {

    this.authService.token$.subscribe((response) => this.authorizationToken = response);
    this.showFile();

  }

  showFile() {

    const xhr = new XMLHttpRequest();
    xhr.open('GET', AppCfg.endpoint + '/file/' + this.token);
    xhr.onreadystatechange = handler;
    xhr.responseType = 'blob';
    xhr.setRequestHeader('Authorization', 'Bearer ' + this.authorizationToken);
    xhr.send();

    function handler() {
      if (this.readyState === this.DONE) {
        if (this.status === 200) {
          // @ts-ignore
          document.querySelector('#documentPreview').src = URL.createObjectURL(this.response);
        }
      }
    }

  }

  /**
   * We need this to provide the URL to google preview so that we have a visualization of the document
   * @param document
   */
  getDocumentUrl(token) {
    this._fileService.downloadFile(token).subscribe((result: HttpResponse<any>) => {
      const fileExt = token + '.' + result.body.type.split('/')[1];
      this._fileService.downloadFromBlob(result.body, fileExt);
    });
  }
}
