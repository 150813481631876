import {HttpClient, HttpClientModule} from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {metaReducers, reducers} from './reducers';
import connections from '../config/connections.json';

// tslint:disable-next-line:max-line-length

import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ToasterService} from './services/toaster/toaster.service';
import {PipesModule} from './pipes.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CommonModule} from '@angular/common';
import {SatPopoverModule} from '@ncstate/sat-popover';
import {NgxUiLoaderConfig, NgxUiLoaderModule} from 'ngx-ui-loader';
import {CarerGuard} from './guards/carer-guard.service';
import {NgxDocViewerModule} from 'ngx-doc-viewer';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {ConnectionLoader, ConnectionModule, CoreModule, GetResourceService, HttpResourceService} from '@softline/core';
import {AuthenticationModule, JwtAuthenticationModule} from '@softline/auth';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {AppEffects} from '../app.effects';
import {NotificationEffects} from './effects/notification.effects';
import {environment} from '../environments/environment';
import {MDBBootstrapModulesPro, ToastModule} from 'ng-uikit-pro-standard';
import {AppUiModule} from './app-ui.module';
import {ToastrModule} from 'ngx-toastr';
import {Observable, of} from 'rxjs';
import {ConnectionState} from '@softline/core/lib/connection/reducers/connection.reducer';
import {CarerdDcumentsModule} from './common-components/carerDocuments/carerdocuments.module';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {ServiceWorkerModule} from '@angular/service-worker';
import {CustomRouteReuseStrategy} from './custom-route-reuse.strategy';
import {NgxMaskModule} from 'ngx-mask';
import {HttpConfigInterceptor} from './interceptors/http.interceptor';
import {CarerSearchComponent} from './modules/carers/components/carer-search/carer-search.component';
import {DateFormatPipe} from './pipes/date.pipe';
import {SoftlineComponentsModule} from '@softline/shared';
import {AllocationTableComponent} from './modules/carers/components/_shared/allocation-table/allocation-table.component';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ViewDcumentsModule} from './common-components/viewDocuments/viewdocuments.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'red',
  // bgsOpacity: 0.5,
  // bgsPosition: POSITION.bottomCenter,
  // bgsSize: 60,
  // bgsType: SPINNER.rectangleBounce,
  fgsColor: 'red',
  // fgsPosition: POSITION.centerCenter,
  // fgsSize: 60,
  // fgsType: SPINNER.chasingDots,
  // logoUrl: 'assets/angular.png',
  pbColor: 'red',
  // pbDirection: PB_DIRECTION.leftToRight,
  // pbThickness: 5,
  // text: 'Welcome to ngx-ui-loader',
  // textColor: '#FFFFFF',
  // textPosition: POSITION.centerCenter
};

export class CustomLoader extends ConnectionLoader {
  load(): Observable<ConnectionState> {
    return of(connections);
  }
}

export function connectionLoader(service: GetResourceService) {
  return new CustomLoader();
}

@NgModule({
  declarations: [
    AppComponent,
    CarerSearchComponent,
    AllocationTableComponent,
  ],
  entryComponents: [
    CarerSearchComponent,
    AllocationTableComponent,
  ],
  imports: [
    NgxDocViewerModule,
    NgxUiLoaderModule,
    NoopAnimationsModule,
    SatPopoverModule,
    CommonModule,
    DragDropModule,
    FlexLayoutModule,
    PipesModule,
    IonicModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AppUiModule,
    FormsModule,
    SoftlineComponentsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),

    MDBBootstrapModulesPro.forRoot(),
    ToastModule.forRoot(),
    ScrollToModule.forRoot(),

    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      closeButton: true,
      progressBar: true,
      maxOpened: 1,
      autoDismiss: true,
      enableHtml: true
    }),

    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    EffectsModule.forRoot([AppEffects, NotificationEffects]),
    StoreRouterConnectingModule.forRoot(),

    AuthenticationModule.forRoot('/account/login', '/'),
    JwtAuthenticationModule,
    CoreModule.forRoot('Carefox', 'Softline-Carefox'),
    ConnectionModule.forRoot({
      loader: {
        provide: ConnectionLoader,
        useFactory: connectionLoader,
        deps: [HttpResourceService]
      }
    }),

    NgxMaskModule.forRoot(),
    CarerdDcumentsModule,
    ViewDcumentsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
  ],
  providers: [
    DeviceDetectorService,
    DateFormatPipe,
    CarerGuard,
    ToasterService,
    StatusBar,
    SplashScreen,
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy
    },
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [
    AppComponent
  ],
  exports: [
    SoftlineComponentsModule,
  ]
})
export class AppModule {
}
