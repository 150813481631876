import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  heading: string;
  content: string;
  action: Subject<any> = new Subject();

  constructor(
    public modalRef: MDBModalRef,
    private _translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.heading = this.heading || this._translateService.instant('CONFIRM_HEADING');
    this.content = this.content || this._translateService.instant('CONFIRM_CONTENT');
  }

  /**
   * Handles cancel button click.
   */
  onCancel(): void {
    this.action.next(false);
    this.modalRef.hide();
  }

  /**
   * Handles confirm button click.
   */
  onConfirm(): void {
    this.action.next(true);
    this.modalRef.hide();
  }

}
