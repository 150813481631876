import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ErrorComponent } from './components/error/error.component';
import { BlankLayoutComponent } from './components/blank-layout/blank-layout.component';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { SoftlineComponentsModule } from '@softline/shared';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { NgxMaskModule } from 'ngx-mask';
import { DownloadComponent } from './components/download/download.component';
import { BusinessDetailsComponent } from './components/business-details/business-details.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    NgxMaskModule,
    MDBBootstrapModulesPro,
    BsDatepickerModule,
    SoftlineComponentsModule,
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    ErrorComponent,
    BlankLayoutComponent,
    MainLayoutComponent,
    ConfirmDialogComponent,
    DownloadComponent,
    BusinessDetailsComponent
  ],
  entryComponents: [
    ConfirmDialogComponent,
    DownloadComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    NgxMaskModule,
    MDBBootstrapModulesPro,
    BsDatepickerModule,
    BusinessDetailsComponent,
    SoftlineComponentsModule,
  ]
})
export class SharedModule {
}
