import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';

@Injectable()
export class CarerGuard implements CanActivate {

    private _allowed = false;

    constructor(
        private router: Router,
    ) {
    }

    canActivate() {
        if (this._allowed) {
            this._allowed = false;
            return true;
        } else {
            this.router.navigate(['/']);
        }
    }
    public set allowed(value: boolean) {
        this._allowed = value;
    }

}
