import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class ToasterService {

  public toasterStatus: BehaviorSubject<Object> = new BehaviorSubject<Object>(null);

  constructor() { }

  show(data): void {
    this.toasterStatus.next(data);
  }

  hide(): void {
    this.toasterStatus.next(null);
  }

}
