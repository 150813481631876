import {Component, Inject, Input, OnInit, Optional, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {map, take, tap} from 'rxjs/operators';
import {PflegerService} from '../../services/pfleger/pfleger.service';
import {NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry} from 'ngx-file-drop';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {AppCfg} from '../../app.config';
import {combineLatest} from 'rxjs';
import {JwtAuthenticationService} from '@softline/auth';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {FileService} from '../../modules/shared/services/file/file.service';

@Component({
  selector: 'carrer-documents',
  templateUrl: './carerdocuments.page.html',
  styleUrls: ['./carerdocuments.page.scss'],
})

export class CarerDocumentsPage implements OnInit {
  public files: NgxFileDropEntry[] = [];
  public step = 0;
  public documents = [];
  public documentTypes = [];
  public token: string = null;
  public carerID: number;
  public formGroup: FormGroup;
  public field = {
    name: 'gueltigvon',
    uid: {}
  };

  @Input() dataInput?: any;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    @Optional() public dialogRef: MatDialogRef<CarerDocumentsPage>,
    public pflegerService: PflegerService,
    public http: HttpClient,
    public authService: JwtAuthenticationService,
    private _toaster: ToastrService,
    private _translate: TranslateService,
    private _fileService: FileService,
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {

    this.formGroup = new FormGroup({
      gueltigvon: new FormControl('', Validators.required),
    });

    this.authService.token$.subscribe((response) => this.token = response);

    this.load();

  }

  load(): void {

    this.documents = [];
    this.carerID = this.data ? this.data.id : this.dataInput.id;
    combineLatest(this.pflegerService.getDocumentsStructure(), this.pflegerService.getDocuments(this.carerID)).pipe(
      map(([types, documents]) => {
        const typesWithDocuments = (<any>types).result.map(o => ({...o, documents: []}));
        for (const document of (<any>documents).result) {
          const t = typesWithDocuments.find(o => o.begriff === document.begriff.begriff);
          t.documents.push(document);
        }
        return typesWithDocuments;
      })
    ).subscribe(o => this.documentTypes = o);
  }

  public dropped(files: NgxFileDropEntry[], documentType: string, autoUpload: boolean) {
    this.files = files;
    for (const droppedFile of files) {

      if (droppedFile.fileEntry.isFile && autoUpload) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          const formData = new FormData();
          formData.append('file', file, droppedFile.relativePath);

          let append = '';
          if (this.formGroup.value && this.formGroup.value.gueltigvon) {
            // formData.append('gueltigvon', this.formGroup.value.gueltigvon.toISOString());
            append = '?gueltigvon=' + this.formGroup.value.gueltigvon.toISOString();
          }

          const headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this.token
          });

          this.http.post(this.pflegerService.getUploadEndpoint(this.carerID, documentType) + append, formData,
            {
              headers: headers,
              responseType: 'blob'
            })
          .subscribe(data => {
            this.files = [];
            this.load();
            this._translate.get('FILE_UPLOADED_SUCCESSFULLY').subscribe((field) => {
              this._toaster.success(
                field,
                'OK',
                {
                  closeButton: true,
                  enableHtml: true,
                  progressBar: true,
                  timeOut: 10000,
                });
            });
          });

        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }
  public uploadDocuments(documentType: string) {
    this.dropped(this.files, documentType, true);
  }

  setStep(index: number) {
    this.step = index;
  }
  nextStep() {
    this.step++;
  }
  prevStep() {
    this.step--;
  }
  fileOver(event) {
  }

  fileLeave(event) {
  }

  /**
   * We need this to provide the URL to google preview so that we have a visualization of the document
   * @param document
   */
  getDocumentUrl(token) {
      this._fileService.downloadFile(token).subscribe((result: HttpResponse<any>) => {
        const fileExt = token + '.' + result.body.type.split('/')[1];
        this._fileService.downloadFromBlob(result.body, fileExt);
      });
  }
}
