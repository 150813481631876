import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppCfg} from '../../app.config';
import {catchError, map, take} from 'rxjs/operators';
import {ConsoleService} from '../console/console.service';
import stringInject from 'stringinject';
import {of} from 'rxjs';
import {ExternalHttpResourceService} from '@softline/core';

@Injectable({
  providedIn: 'root'
})

export class PflegerService {

  /**
   * TODO: use it later for cache purpose.
   */
  public results: any = null;

  constructor(
    private _http: HttpClient,
    private _externalHttp: ExternalHttpResourceService,
    private _console: ConsoleService,
  ) {

    /**
     * we need the structure at the begining, so let's cache it for the moment;
     * TODO: move it to the localstorage with a cookie based id so we can set it to expire
     * TODO: the target is to not query the structure for every request
     */
  }

  /**
   * Retrieve the form Structure from CLASS.definition
   */
  getStructure() {

    const endpoint = AppCfg.endpoint + AppCfg.attributesEndpoint.pfleger.definition;
    const cacheDefinition = this.getCacheRequest(endpoint);

    if (cacheDefinition) {
      return of(cacheDefinition);
    }

    return this._http.get(endpoint).pipe(
      map((data: any) => {
        this.setCacheRequest(endpoint, data);
        return data;
      }),
    );
  }

  /**
   * Retrieve the form Structure from CLASS.definition
   */
  getAgenciesList() {
    return this._http.get(AppCfg.endpoint + AppCfg.attributesEndpoint.pfleger.agencies.list);
  }
  /**
   * Retrieve the form Structure CRUD
   */
  getModuleStructure(module: string) {

    const endpoint = AppCfg.endpoint + module;
    const cacheDefinition = this.getCacheRequest(endpoint);

    if (cacheDefinition) {
      return of(cacheDefinition);
    }

    return this._http.get(endpoint).pipe(
      map((data: any) => {
        this.setCacheRequest(endpoint, data);
        return data;
      }),
    );
  }

  /**
   * Update Procedure CRUD
   * @param endpoint
   * @param data
   */
  updateModule(endpoint: string, data: any) {
    return this._http.put(AppCfg.endpoint + endpoint, data);
  }

  /**
   * Update Procedure CRUD
   * @param endpoint
   * @param data
   */
  createModule(endpoint: string, data: any) {
    return this._http.post(AppCfg.endpoint + endpoint, data);
  }

  /**
   * Retrieve the form Structure from CLASS.definition
   */
  getPflegerabbruchStructure() {

    const endpoint = AppCfg.endpoint + AppCfg.attributesEndpoint.pfleger.pflegerabbruch.definition;
    const cacheDefinition = this.getCacheRequest(endpoint);

    if (cacheDefinition) {
      return of(cacheDefinition);
    }
    return this._http.get(endpoint).pipe(
      map((data: any) => {
        this.setCacheRequest(endpoint, data);
        return data;
      }),
    );

  }
  getPflegerabbruchData(idcaregiver: number) {
    return this._http.get(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.pflegerabbruch.query, {idcaregiver}));
  }

  /**
   * Retrieve the form Structure from CLASS.definition
   */
  getPpflegerstammVermerkStructure() {

    const endpoint = AppCfg.endpoint + AppCfg.attributesEndpoint.pfleger.pflegerstammvermerk.definition;
    const cacheDefinition = this.getCacheRequest(endpoint);

    if (cacheDefinition) {
      return of(cacheDefinition);
    }
    return this._http.get(endpoint).pipe(
      map((data: any) => {
        this.setCacheRequest(endpoint, data);
        return data;
      }),
    );
  }
  getPpflegerstammVermerkData(idcaregiver: number) {
    return this._http.get(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.pflegerstammvermerk.query, {idcaregiver}));
  }
  removeVermerk(idpflegerstamm: number, id: number) {
    return this._http.delete(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.pflegerstammvermerk.delete, {
      idpflegerstamm: idpflegerstamm,
      id: id
    }));
  }

  getUploadEndpoint(idpfleger: number, documentType: string) {
    return AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.documents.upload, {
      idcaregiver: idpfleger,
      term: documentType
    });
  }
  getPatientUploadEndpoint(idallocation: number, idpatient: number, documentType: string) {
    return AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.documents.patientUpload, {
      idallocation: idallocation,
      idpatient: idpatient,
      term: documentType
    });
  }

  /**
   * Retrieve the form Structure from CLASS.definition
   */
  getPflegerInsuranceHistoryStructure() {

    const endpoint = AppCfg.endpoint + AppCfg.attributesEndpoint.pfleger.sva.definition;
    const cacheDefinition = this.getCacheRequest(endpoint);

    if (cacheDefinition) {
      return of(cacheDefinition);
    }
    return this._http.get(endpoint).pipe(
      map((data: any) => {
        this.setCacheRequest(endpoint, data);
        return data;
      }),
    );

  }

  /**
   * Retrieve the form Structure from CLASS.definition
   */
  getPflegerInsuranceHistoryDetailsStructure() {
    const endpoint = AppCfg.endpoint + AppCfg.attributesEndpoint.pfleger.sva.svaPaymentsDefinition;
    const cacheDefinition = this.getCacheRequest(endpoint);

    if (cacheDefinition) {
      return of(cacheDefinition);
    }
    return this._http.get(endpoint).pipe(
      map((data: any) => {
        this.setCacheRequest(endpoint, data);
        return data;
      }),
    );

  }
  getPflegerInsuranceData(idcaregiver: number) {
    return this._http.get(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.sva.query, {idcaregiver}));
  }
  getPflegerInsuranceDetailsData(idcaregiver: number) {
    return this._http.get(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.sva.getSvaInstallmentPayments, {idcaregiver}));
  }

  /**
   * Retrieve the form Structure from CLASS.definition
   */
  getBusinessHistoryDefinition() {

    const endpoint = AppCfg.endpoint + AppCfg.attributesEndpoint.pfleger.businessStatus.history.definition;
    const cacheDefinition = this.getCacheRequest(endpoint);

    if (cacheDefinition) {
      return of(cacheDefinition);
    }
    return this._http.get(endpoint).pipe(
      map((data: any) => {
        this.setCacheRequest(endpoint, data);
        return data;
      }),
    );

  }
  getBusinessHistoryData(idcaregiver: number) {
    return this._http.get(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.businessStatus.history.query, {idcaregiver}));
  }

  /**
   * Retrieve the form Structure from CLASS.definition
   */
  getPflegerHistoryStructure() {

    const endpoint = AppCfg.endpoint + AppCfg.attributesEndpoint.pfleger.agencies.definition;
    const cacheDefinition = this.getCacheRequest(endpoint);

    if (cacheDefinition) {
      return of(cacheDefinition);
    }
    return this._http.get(endpoint).pipe(
      map((data: any) => {
        this.setCacheRequest(endpoint, data);
        return data;
      }),
    );

  }
  getPflegerHistoryData(caregiverid: number) {
    return this._http.get(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.agencies.query, {caregiverid}));
  }

  /**
   * Retrieve the form Structure from CLASS.definition
   */
  getPlfegerAngeciesHistoryStructure() {

    const endpoint = AppCfg.endpoint + AppCfg.attributesEndpoint.pfleger.agencies.definition;
    const cacheDefinition = this.getCacheRequest(endpoint);

    if (cacheDefinition) {
      return of(cacheDefinition);
    }
    return this._http.get(endpoint).pipe(
      map((data: any) => {
        this.setCacheRequest(endpoint, data);
        return data;
      }),
    );

  }
  getPlfegerAngeciesHistoryData(caregiverid: number) {
    return this._http.get(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.agencies.query, {caregiverid}));
  }

  /**
   * Retrieve the form Structure from CLASS.definition
   */
  getPatientsHistoryStructure() {

    const endpoint = AppCfg.endpoint + AppCfg.attributesEndpoint.pfleger.patients.definition;
    const cacheDefinition = this.getCacheRequest(endpoint);

    if (cacheDefinition) {
      return of(cacheDefinition);
    }
    return this._http.get(endpoint).pipe(
      map((data: any) => {
        this.setCacheRequest(endpoint, data);
        return data;
      }),
    );

  }
  getPatientsHistoryData(idcaregiver: number) {
    return this._http.get(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.patients.query, {idcaregiver}));
  }

  /**
   * Retriever all carers
   * @param searchParameters
   */
  getList(searchParameters) {
    return this._http.get(
      AppCfg.endpoint + AppCfg.attributesEndpoint.pfleger.results,
      {params: searchParameters}
    ).pipe(
      map((data: any) => data),
    );
  }
  /**
   * Retriever all allocations
   * @param searchParameters
   */
  getAllocationList(searchParameters) {
    return this._http.get(
      AppCfg.endpoint + AppCfg.attributesEndpoint.pfleger.allocations.search,
      {params: searchParameters}
    ).pipe(
      map((data: any) => data),
    );
  }

  /**
   * Search and assign
   * @param searchParameters
   */
  searchAndAssign(searchParameters) {
    return this._http.get(
      AppCfg.endpoint + AppCfg.attributesEndpoint.pfleger.searchAndAssign,
      {params: searchParameters}
    );
  }

  /**
   * Retrieve the UID definition for the table
   * @param beanDefinition
   */
  getUID(beanDefinition) {

    /**
     * OPTION 1: POST
     * USED FOR REMOTE UID DEFINITION
     * REQUIRED BODY: {'beanDefinition': beanDefinition}
     *
     */
    //
    // return this._http.post('https://relay.tuxido.ro/api/web/v1/uids',
    //   {'beanDefinition': beanDefinition}
    // ).pipe(
    //   map((data: any) => data),
    // );
    /**
     * OPTION 2: GET
     * USED FOR LOCAL JSON CONFIG
     */
    return this._http.get('./assets/uid/' + beanDefinition + '.json').pipe(
      map((data: any) => data),
      catchError(() => of({}))
    );
  }

  /**
   * Retrieve Carer Turnus definition
   */
  getTurnusHistoryDefinition() {

    const endpoint = AppCfg.endpoint + AppCfg.attributesEndpoint.pfleger.turnus.history.definition;
    const cacheDefinition = this.getCacheRequest(endpoint);

    if (cacheDefinition) {
      return of(cacheDefinition);
    }
    return this._http.get(endpoint).pipe(
      map((data: any) => {
        this.setCacheRequest(endpoint, data);
        return data;
      }),
    );
  }

  /**
   * Retrieve Carer Turnus History by ID
   * @param idpfleger
   */
  getTurnusHistoryData(idcaregiver: number) {
    return this._http.get(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.turnus.history.query, {idcaregiver}));
  }

  /**
   * Get the Business Status
   * @param idcaregiver
   * @param checkNow
   */
  getBusiness(idcaregiver: any, checkNow: any) {
    if (idcaregiver === 5) {
      return of({});
    }
    checkNow = String(checkNow);
    return this._http.get(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.businessStatus.query, {idcaregiver, checkNow}));
  }
  /**
   * Get the Business Status
   * @param idcaregiver
   * @param checkNow
   */
  getFirmaStatus(data: any) {
    return this._http.post(AppCfg.attributesEndpoint.pfleger.businessStatus.firmaCheck, data);
  }

  /**
   * Retrieve Carer by ID
   * @param idcaregiver
   */
  getById(idcaregiver: number) {
    return this._http.get(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.profile, {idcaregiver}));
  }
  /**
   * Retrieve Carer by Allocation ID
   * @param idcaregiver
   */
  getByAllocationId(idcaregiver: number) {
    return this._http.get(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.profilebyAllocation, {idcaregiver}));
  }

  /**
   * Register Procedure
   * @param carer
   */
  register(carer: any) {
    return this._http.post(AppCfg.endpoint + `/pfleger/register`, carer);
  }

  /**
   * Update Procedure
   * @param data
   * @param idpfleger
   */
  update(data: any, idcaregiver: number) {
    if (idcaregiver) {
      return this._http.put(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.profile, {idcaregiver}), data);
    } else {
      return this._http.post(AppCfg.endpoint + AppCfg.attributesEndpoint.pfleger.create, data);
    }

  }

  /**
   * Delete Procedure
   * @param id
   */
  delete(id: number) {
    return this._http.delete(AppCfg.endpoint + `/pfleger/${id}`);
  }

  getDocumentsStructure() {
    return this._http.get(AppCfg.endpoint + AppCfg.attributesEndpoint.pfleger.documents.structure);
  }
  getDocuments(caregiverid: number) {
    return this._http.get(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.documents.list, {caregiverid}));
  }

  getPatientDocumentsStructure() {
    return this._http.get(AppCfg.endpoint + AppCfg.attributesEndpoint.pfleger.documents.patientDocumentStructure);
  }

  getPatientHistoryDefinition() {
    const endpoint = AppCfg.endpoint + AppCfg.attributesEndpoint.pfleger.patients.definition;
    const cacheDefinition = this.getCacheRequest(endpoint);

    if (cacheDefinition) {
      return of(cacheDefinition);
    }
    return this._http.get(endpoint).pipe(
      map((data: any) => {
        this.setCacheRequest(endpoint, data);
        return data;
      }),
    );
  }
  getPatientHistoryData(idcaregiver: number) {
    return this._http.get(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.patients.query, {idcaregiver}));
  }

  getPatientDocumentsDefinition() {
    const endpoint = AppCfg.endpoint + AppCfg.attributesEndpoint.pfleger.patients.documents.definition;
    const cacheDefinition = this.getCacheRequest(endpoint);

    if (cacheDefinition) {
      return of(cacheDefinition);
    }
    return this._http.get(endpoint).pipe(
      map((data: any) => {
        this.setCacheRequest(endpoint, data);
        return data;
      }),
    );
  }
  getPatientDocumentsData(patientid: number, idallocation: number) {
    return this._http.get(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.patients.documents.query, {patientid, idallocation}));
  }

  getDocumentContent(document: any) {
    return this._http.get(AppCfg.endpoint + '/file/' + document.hash);
  }

  /**
   * Prepare the data to be sent oback to the server.
   * Create multi depth layer from objects with <table>.<relational>
   * Create single depth layer objects
   * @param data
   */
  prepare(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(data);
    });
  }

  getCacheRequest(endpoint) {
    return (localStorage.getItem(endpoint) ? JSON.parse(localStorage.getItem(endpoint)) : null);
  }
  setCacheRequest(endpoint, response) {
    return localStorage.setItem(endpoint, JSON.stringify(response));
  }

}
