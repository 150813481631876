import {Pipe, PipeTransform, SkipSelf} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ExternalBlobHttpResourceService} from '@softline/core';

@Pipe({
    name: 'secureImage'
})
export class SecureImagePipe implements PipeTransform {

    constructor(private resourceService: ExternalBlobHttpResourceService, private sanitizer: DomSanitizer) {
    }

    transform(url: string): Observable<SafeUrl> {
        return this.resourceService.get(url)
        .pipe(
            map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val.result)))
        );
    }

}
