import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {AuthenticationService, JwtAuthenticationService} from '@softline/auth';
import {AppCfg} from '../../../../app.config';
import {PflegerService} from '../../../../services/pfleger/pfleger.service';
import {ToasterService} from '../../../../services/toaster/toaster.service';
import {ToastrService} from 'ngx-toastr';
import {CarerSearchComponent} from '../../../carers/components/carer-search/carer-search.component';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  appLanguages: any[] = AppCfg.availableLanguages;
  currentLanguage: string = null;
  appPages: any[] = [];
  carerID: number;
  currentUser: any = {};
  public modalRef: MDBModalRef;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private authenticationService: JwtAuthenticationService,
    private _pflegeProvider: PflegerService,
    private _translate: TranslateService,
    private _toaster: ToastrService,
    private modalService: MDBModalService,
    public authService: AuthenticationService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.authService.getInfo().subscribe((response: any) => {
      this.currentUser = response;
    });
    this.currentLanguage = localStorage.getItem('language') || 'de';
    this.appPages = [
      {
        label: '#CARER.LIST',
        icon: 'fal fa-users',
        url: ['/carers'],
      },
      {
        label: '#TRANSPORT.LIST',
        icon: 'fal fa-shuttle-van',
        url: ['/transports'],
      },
    ];
  }

  viewCarerProfile() {

    this._pflegeProvider.getByAllocationId(this.carerID).subscribe(
      (data: any) => {
        if (data && data.result && data.result.id) {
          this.router.navigate(['/carers', data.result.id, 'update']);
        } else {
          this._translate.get('UNABLE_TO_FIND_USER').subscribe((field) => {
            this._toaster.error(
              field,
              'Error',
              {
                closeButton: true,
                enableHtml: true,
                progressBar: true,
                timeOut: 10000,
              });
          });
        }
      },
      () => {
        this._translate.get('UNABLE_TO_FIND_USER').subscribe((field) => {
          this._toaster.error(
            field,
            'Error',
            {
              closeButton: true,
              enableHtml: true,
              progressBar: true,
              timeOut: 10000,
            });
        });
      }
    );

  }

  addCarer(): void {
    this.modalRef = this.modalService.show(CarerSearchComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered',
      containerClass: '',
      animated: false
    });
  }

  /**
   * Changes the application language.
   *
   * @param language String
   */
  changeLanguage(language) {
    this.currentLanguage = language;
    localStorage.setItem('language', language);
    this.translateService.use(this.currentLanguage);
  }

  /**
   * Logs out the current user.
   */
  logout(): void {
    this.authenticationService.deleteToken();
    localStorage.clear();
    this.router.navigateByUrl('/account/login');
  }
}
