import {ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {DownloadComponent} from '../../components/download/download.component';
import {AppCfg} from '../../../../app.config';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  config: any = {};

  constructor(
    private _sanitizer: DomSanitizer,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _appRef: ApplicationRef,
    private _injector: Injector,
    private _http: HttpClient
  ) {
  }

  downloadFile(token): Observable<Object> {
    const HTTPOptions = {
      observe: 'response' as 'body',
      'responseType': 'blob' as 'json'
    };
    return this._http.get(AppCfg.endpoint + '/file/' + token, HTTPOptions);
  }

  /**
   * Downloads a file from a given set of data.
   *
   * @param data BlobPart data
   * @param name File name with extension
   * @param type Type of the document.
   */
  downloadFromBlob(data: any, name: string, type?: string) {
    if (!type) {
      const fileTypes = {
        'pdf': 'application/pdf'
      };
      type = fileTypes[name.substr(name.lastIndexOf('.') + 1)];
    }

    const blob = new Blob([data], {
      type: type || 'application/octet-stream'
    });


    const url = window.URL.createObjectURL(blob);

    this.config = {
      url: this._sanitizer.bypassSecurityTrustResourceUrl(url),
      name: name,
    };

    const componentRef = this.appendComponentToBody(DownloadComponent, {
      config: this.config
    });
    setTimeout(() => {
      document.getElementById('download-element-ref').click();

      // Remove the component from ng tree and from DOM
      this._appRef.detachView(componentRef.hostView);
      componentRef.destroy();
    });
  }

  /**
   * Appends a component to DOM.
   *
   * @param component
   * @param componentProps
   * @link https://medium.com/hackernoon/angular-pro-tip-how-to-dynamically-create-components-in-body-ba200cc289e6
   */
  appendComponentToBody(component: any, componentProps?: object) {
    // 1. Create a component reference from the component
    const componentRef = this._componentFactoryResolver
    .resolveComponentFactory(component)
    .create(this._injector);

    if (componentProps && typeof componentRef.instance === 'object') {
      Object.assign(componentRef.instance as object, componentProps);
    }

    // 2. Attach component to the _appRef so that it's inside the ng component tree
    this._appRef.attachView(componentRef.hostView);

    // 3. Get DOM element from component
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // 4. Append DOM element to body
    document.body.appendChild(domElem);

    return componentRef;
  }
}
