import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {UIDDefinition} from '@softline/shared';
import {FormControl, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {PflegerService} from '../../../../services/pfleger/pfleger.service';
import {NavController} from '@ionic/angular';
import {CarerGuard} from '../../../../guards/carer-guard.service';
import {DateFormatPipe} from '../../../../pipes/date.pipe';
import moment from 'moment';
import {take} from 'rxjs/operators';
import {MDBModalRef} from 'ng-uikit-pro-standard';
import {Router} from '@angular/router';

@Component({
  selector: 'app-carer-search',
  templateUrl: './carer-search.component.html',
  styleUrls: ['./carer-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CarerSearchComponent implements OnInit {

  public uid: UIDDefinition;
  public field = {
    name: 'birthdate',
    title: 'Date of Birth',
  };

  showExtra: boolean;

  @ViewChild('documentEditForm', {static: true}) documentEditForm: FormGroupDirective;

  searchCarerForm = new FormGroup({
    lastname: new FormControl(null, Validators.required),
    birthdate: new FormControl(null, Validators.required),
    firstname: new FormControl(null),
    socialNumber: new FormControl(null),
  });

  constructor(
    private _translate: TranslateService,
    private _toaster: ToastrService,
    private pflegerService: PflegerService,
    private _router: Router,
    private careGuard: CarerGuard,
    public modalRef: MDBModalRef
  ) {
  }

  ngOnInit(): void {
    this.showExtra = false;
  }

  searchCarer(form: FormGroup): void {
    if (form.valid) {
      const searchCriteria: any = {
        lastname: form.value.lastname,
        birthdate: moment(form.value.birthdate.toISOString()).format('YYYY-MM-DD')
      };

      // First, check only the last name and date of birth
      this.pflegerService.searchAndAssign(searchCriteria)
        .pipe(take(1))
        .subscribe(
          (response: any) => this.handleSearchResponse(response),
          (error: any) => {
            if (error.status === 400) {
              // Add the firstname to the payload and retry the search request
              if (form.value.firstname) {
                searchCriteria.firstname = form.value.firstname;
                this.retrySearchAndAssign(searchCriteria);
              } else {
                this._toaster.error(this._translate.instant('MORE_THAN_ONE_IN_THE_SYSTEM'), 'ERROR');
              }
            }
          }
        );
    }
  }

  retrySearchAndAssign(searchCriteria): void {
    this.pflegerService.searchAndAssign(searchCriteria)
      .pipe(take(1))
      .subscribe(
        (response: any) => this.handleSearchResponse(response),
        (error: any) => {
          if (error.status === 400) {
            this._toaster.error(this._translate.instant('MORE_THAN_ONE_IN_THE_SYSTEM'), 'ERROR');
          }
        }
      );
  }

  handleSearchResponse(response): void {
    if (response.result.length === 0) {
      localStorage.setItem('defaultData', JSON.stringify(this.searchCarerForm.value));
      this.modalRef.hide();

      this.careGuard.allowed = true; // we allow the user to navigate to create, only once with the CareGuard
      this._router.navigate(['/carers/create']);
    } else {
      this.modalRef.hide();

      if (response.result && !response.result.id) {
        this._toaster.error(this._translate.instant('UNABLE_TO_GET_CARERID'), 'ERROR');
      } else {
        this._router.navigate(['/carers', response.result.id, 'update']);
      }
    }
  }
}
