import {Injectable} from '@angular/core';
import {ToastService} from 'ng-uikit-pro-standard';
import {act, Actions, createEffect, ofType} from '@ngrx/effects';
import {tap} from 'rxjs/operators';
import {NotificationActions} from '@softline/core';
import {AppCfg} from '../app.config';

@Injectable()
export class NotificationEffects {

  notify = createEffect(() =>
      this.actions$.pipe(
        ofType(NotificationActions.dispatch),
        tap(action => {
          if (action.notification.type === 'error' && action.notification.priority === 3 && action.notification.source === '@softline/core') {
            return;
          }
          switch (action.notification.type) {
            case 'error':
              this.toastService.error(action.notification.subject);
              break;
            case 'warning':
              this.toastService.warning(action.notification.subject);
              break;
            case 'success':
              this.toastService.success(action.notification.subject);
            case 'info':
              this.toastService.info(action.notification.subject);
              break;
            default:
              break;
          }
        })
      ),
    {dispatch: false}
  );

  constructor(private actions$: Actions, private toastService: ToastService) {
  }
}
