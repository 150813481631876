import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MDBBootstrapModulesPro, WavesModule} from 'ng-uikit-pro-standard';
import { ClickOutsideModule } from 'ng-click-outside';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,

    ReactiveFormsModule,
    FormsModule,

    // OverlayPanelModule,
    // ButtonModule,
    FlexLayoutModule,
    // CheckboxModule,
    // InputTextModule,
    // DropdownModule,
    // InputSwitchModule,
    // ButtonModule,
    // InputTextModule,
    // TieredMenuModule,
    // MenubarModule,

    // NGX Translate
    TranslateModule,

    // MDB
    WavesModule,
    MDBBootstrapModulesPro,
    ClickOutsideModule
  ],
  exports: [

    ReactiveFormsModule,
    FormsModule,

    // OverlayPanelModule,
    // ButtonModule,
    // CheckboxModule,
    // InputTextModule,
    // DropdownModule,
    // InputSwitchModule,
    FlexLayoutModule,
    // ButtonModule,
    // InputTextModule,
    // TieredMenuModule,
    // MenubarModule,

    // NGX Translate
    TranslateModule,

    // MDB
    WavesModule,
    MDBBootstrapModulesPro,
    ClickOutsideModule
  ]
})
export class AppUiModule {
}
