export const UIDConfig: any = {

  /**
   * [1] BEAN DEFINITIONS:
   * configuration type: objects
   *
   * [1.1] order: will be used to define the sorting order of the group: LOW to HIGH
   * [1.2] name: group name - from the database perspective

   * [1.3] title: web version will be the tab's name, and we will hide this title on responsive design and leave icon only
   * [1.4] icon: web perspective, will be used to make the tab pretty and user friendly, and use it for responsive design

   * [1.5] active: we need to highlight the tab and set it highlighted
   * [1.6] available: we need to make sure the user has access to it by default all will be true,

   * [1.6] message:
   *    [1.7.1] text: a global message for the user
   *    [1.7.2] position: top|bottom  [ containing maybe some information on how to fill the inputs ]
   */

  /**
   * [2] GROUPS DEFINITION
   * configuration type: list of objects
   *
   * reference: "personalDetails",
   * class: "col-12",
   * title: "Personal Details",
   * icon: false,
   *
   */


  /**
   * DATABASE FIELD DESIGN DEFINITION
   *
   *  [3.1] order: sorting order inside the group: LOW to HIGH
   *  [3.2] attribute:
   *  [3.3] isToggle: decide if this input is a toggle like input [ applicable only on integer values ]

   *  [3.4] group: unique group name trough the form, check [1.2]
   *
   *  [3.5] placeholder: a placeholder to be displayed inside the form input area, eg: joe.doe@email.com
   *  [3.6] inputMask: a regex included as a pre frontend validation || to serve as input guidance || pre format the input
   *  [3.7] defaultValue: to be used in case we will be using this to relate with another field and reference
   *  [3.8] class: 3|4|6|8|9|12 we decide how much will the input take out, of a 12 grid system

   *  [3.9] beanClass:  we will be using this to determine if the input will require a sub query to another bean definition
   *                    if this will be filled in, it means we will open a modal,
   *                    with the requested fields by the sub class model definition

   *  [3.10] visibility: decide if the field is visible by default
   *  [3.10.1] hidden: true|false - visible by default
   *  [3.10.2] target: definition of a remote field inside the same bean
   *  [3.10.2.1] field: <databaseTable>.<field> eg: Softline.Globalcare.StammTables.Pfleger.kzfamilienstand
   *  [3.10.2.2] value: 'M' - checking if the value of Softline.Globalcare.StammTables.Pfleger.kzfamilienstand == M
   *  [3.10.3] matchExactly: check if it exactly the same as the target.field.value
   *  [3.10.4] greaterThan: to be used if the value is integer || date
   *  [3.10.5] lowerThan: to be used if the value is integer || date
   *
   *  [3.11] icon: we can use multiple icons, one for the prefix and one for the suffix
   *  [3.11.1] name: icon reference in the font
   *  [3.11.2] placement: prefix|suffix - to be used either in front of the input or inject it at the end of it
   *  [3.11.3] nullVisibility:  show the icon if the value is empty
   *  [3.11.4] type: font definition // this will be ignored for the moment, we will be using fontawesome 5 pro only
   *  [3.11] clickAction: we can use a plain function here; eg: clear the value
   *
   *  [3.12] label: objects
   *  [3.12.1]  title: the title of the input
   *  [3.12.2]  class: additional class to push to the label class
   *
   *  [3.13] validations: a list of objects
   *  [3.13.1]    name: required|pattern - we use this to switch in the form generation between required and pattern
   *  [3.13.2]    validator: required | <regex> - we can define here either required or a regex to match against,
   *  [3.13.2]    message: <string> - to be displayed when then validation fails
   *
   *
   *  [3.14]   searchable
   *  [3.14.1]  serverside: false|true - do we need to get a serverside request?
   *  [3.14.2]  endpoint: <url> - specify the endpoint for the request - we need a JSON request to be set as response
   }
   *
   */


  'pfleger': {

    groups: [
      {
        reference: 'personalDetails',
        name: 'personalDetails',
        class: 'col-12',
        title: 'Personal Details',
        icon: 'user',
        active: true,
        available: true,
        message: {
          text: 'Loreum ipsum',
          position: 'top',
        },
      },
      {
        reference: 'partnerDetails',
        name: 'partnerDetails',
        class: 'col-12',
        title: 'Partner Details',
        icon: 'user-tie',
        active: true,
        available: true,
        message: null,
      }
    ],

    /**
     * The other of the Definition will be the order of their display order in the form.
     * We need this done this way, because it offers more flexibility and we do not have to reorder the entire object
     * if we will be adding a new field later on.
     */
    definitions: [

      {
        name: 'vorname',
        isToggle: false,

        group: 'personalDetails',

        placeholder: 'testing placeholder',
        inputMask: false,
        defaultValue: null,

        design: {
          forceResponsive: false,
          readonly: false,
          inline: true,
          responsive: 3
        },

        beanClass: false,

        visibility: {

          hidden: false,

          target: {
            field: 'otherField',
            value: 5,
          },

          matchExactly: false,
          greaterThan: true,
          lowerThan: false,

        },

        icon: [

          {
            name: 'user',
            placement: 'prefix',
            nullVisibility: true,
          },

        ],

        label: {
          title: 'First Name',
          class: 'label',
        },

        validations: [

          {
            name: 'required',
            validator: 'required',
            message: 'This field is mandatory'
          },
          {
            name: 'pattern',
            validator: '^[a-zA-Z]+$',
            message: 'Please use letters only (A-Z)'
          }

        ],

      },
      {

        name: 'nachname',
        isToggle: false,

        group: 'personalDetails',

        placeholder: false,
        inputMask: false,
        defaultValue: null,
        design: {
          forceResponsive: false,
          inline: true,
          responsive: 3

        },

        beanClass: false,

        visibility: {

          hidden: false,

          target: {
            field: 'otherField',
            value: 5,
          },

          matchExactly: false,
          greaterThan: true,
          lowerThan: false,

        },

        icon: [

          {
            name: 'user',
            placement: 'prefix',
            nullVisibility: true,
          },

        ],

        label: {
          title: 'First Name',
          class: 'label',
        },

        validations: [

          {
            name: 'required',
            validator: 'required',
            message: 'This field is mandatory'
          },

        ],

      },
      {

        name: 'nachnameurspruenglich',
        isToggle: false,

        group: 'personalDetails',

        placeholder: false,
        inputMask: false,
        defaultValue: null,
        design: {
          forceResponsive: false,
          inline: true,
          responsive: 3

        },

        beanClass: false,

        visibility: {

          hidden: false,

          target: {
            field: 'otherField',
            value: 5,
          },

          matchExactly: false,
          greaterThan: true,
          lowerThan: false,

        },

        icon: [

          {
            name: 'user',
            placement: 'prefix',
            nullVisibility: true,
          },

        ],

        label: {
          title: 'First Name',
          class: 'label',
        },

        validations: [

          {
            name: 'required',
            validator: 'required',
            message: 'This field is mandatory'
          },

        ],

      },
      {

        name: 'kzgeschlecht',
        isToggle: false,

        group: 'personalDetails',

        placeholder: false,
        inputMask: false,
        defaultValue: null,
        design: {
          forceResponsive: false,
          inline: true,
          responsive: 6

        },

        beanClass: false,

        visibility: {

          hidden: false,

          target: {
            field: 'otherField',
            value: 5,
          },

          matchExactly: false,
          greaterThan: true,
          lowerThan: false,

        },

        label: {
          title: 'First Name',
          class: 'label',
        },

        validations: [

          {
            name: 'required',
            validator: 'required',
            message: 'This field is mandatory'
          },

        ],

      },
      {

        name: 'geburtsdatum',
        isToggle: false,

        group: 'personalDetails',

        placeholder: false,
        inputMask: false,
        defaultValue: null,
        design: {
          forceResponsive: false,
          inline: true,
          responsive: 6

        },

        beanClass: false,

        visibility: {

          hidden: false,

          target: {
            field: 'otherField',
            value: 5,
          },

          matchExactly: false,
          greaterThan: true,
          lowerThan: false,

        },

        label: {
          title: 'First Name',
          class: 'label',
        },

        validations: [

          {
            name: 'required',
            validator: 'required',
            message: 'This field is mandatory'
          },

        ],

      },
      {

        name: 'strasse',
        isToggle: false,

        group: 'partnerDetails',

        placeholder: false,
        inputMask: false,
        defaultValue: null,
        design: {
          forceResponsive: false,
          inline: true,
          responsive: 12

        },

        beanClass: false,

        visibility: {

          hidden: false,

          target: {
            field: 'otherField',
            value: 5,
          },

          matchExactly: false,
          greaterThan: true,
          lowerThan: false,

        },

        label: {
          title: 'First Name',
          class: 'label',
        },

        validations: [

          {
            name: 'required',
            validator: 'required',
            message: 'This field is mandatory'
          },

        ],

      },
      {

        name: 'postleitzahl',
        isToggle: false,

        group: 'personalDetails',

        placeholder: false,
        inputMask: false,
        defaultValue: null,
        design: {
          forceResponsive: false,
          inline: true,
          responsive: 6

        },

        beanClass: false,

        visibility: {

          hidden: false,

          target: {
            field: 'otherField',
            value: 5,
          },

          matchExactly: false,
          greaterThan: true,
          lowerThan: false,

        },

        label: {
          title: 'First Name',
          class: 'label',
        },

        validations: [

          {
            name: 'required',
            validator: 'required',
            message: 'This field is mandatory'
          },

        ],

      },
      {

        name: 'ort',
        isToggle: false,

        group: 'personalDetails',

        placeholder: false,
        inputMask: false,
        defaultValue: null,
        design: {
          forceResponsive: false,
          inline: true,
          responsive: 6

        },

        beanClass: false,

        visibility: {

          hidden: false,

          target: {
            field: 'otherField',
            value: 5,
          },

          matchExactly: false,
          greaterThan: true,
          lowerThan: false,

        },

        label: {
          title: 'First Name',
          class: 'label',
        },

        validations: [

          {
            name: 'required',
            validator: 'required',
            message: 'This field is mandatory'
          },

        ],

      },
      {

        name: 'geburtsort',
        isToggle: false,

        group: 'personalDetails',

        placeholder: false,
        inputMask: false,
        defaultValue: null,
        design: {
          forceResponsive: false,
          inline: true,
          responsive: 6

        },

        beanClass: false,

        visibility: {

          hidden: false,

          target: {
            field: 'otherField',
            value: 5,
          },

          matchExactly: false,
          greaterThan: true,
          lowerThan: false,

        },

        label: {
          title: 'First Name',
          class: 'label',
        },

        validations: [

          {
            name: 'required',
            validator: 'required',
            message: 'This field is mandatory'
          },

        ],

      },
      {

        name: 'kzfamilienstand',
        isToggle: false,

        group: 'personalDetails',

        placeholder: false,
        inputMask: false,
        defaultValue: null,
        design: {
          forceResponsive: false,
          inline: true,
          responsive: 6

        },

        beanClass: false,

        visibility: {

          hidden: false,

          target: {
            field: 'otherField',
            value: 5,
          },

          matchExactly: false,
          greaterThan: true,
          lowerThan: false,

        },

        label: {
          title: 'First Name',
          class: 'label',
        },

        validations: [

          {
            name: 'required',
            validator: 'required',
            message: 'This field is mandatory'
          },

        ],

      },
      {

        name: 'mail1',
        isToggle: false,

        group: 'personalDetails',

        placeholder: false,
        inputMask: false,
        defaultValue: null,
        design: {
          forceResponsive: false,
          inline: true,
          responsive: 6

        },

        beanClass: false,

        visibility: {

          hidden: false,

          target: {
            field: 'otherField',
            value: 5,
          },

          matchExactly: false,
          greaterThan: true,
          lowerThan: false,

        },

        label: {
          title: 'First Name',
          class: 'label',
        },

        validations: [

          {
            name: 'required',
            validator: 'required',
            message: 'This field is mandatory'
          },

        ],

      },
      {

        name: 'mail2',
        isToggle: false,

        group: 'personalDetails',

        placeholder: false,
        inputMask: false,
        defaultValue: null,
        design: {
          forceResponsive: false,
          inline: true,
          responsive: 6

        },

        beanClass: false,

        visibility: {

          hidden: false,

          target: {
            field: 'otherField',
            value: 5,
          },

          matchExactly: false,
          greaterThan: true,
          lowerThan: false,

        },

        label: {
          title: 'First Name',
          class: 'label',
        },

        validations: [

          {
            name: 'required',
            validator: 'required',
            message: 'This field is mandatory'
          },

        ],

      },
      {

        name: 'mail3',
        isToggle: false,

        group: 'personalDetails',

        placeholder: false,
        inputMask: false,
        defaultValue: null,
        design: {
          forceResponsive: false,
          inline: true,
          responsive: 6

        },

        beanClass: false,

        visibility: {

          hidden: false,

          target: {
            field: 'otherField',
            value: 5,
          },

          matchExactly: false,
          greaterThan: true,
          lowerThan: false,

        },

        label: {
          title: 'First Name',
          class: 'label',
        },

        validations: [

          {
            name: 'required',
            validator: 'required',
            message: 'This field is mandatory'
          },

        ],

      },

    ],

    definitionList: null

  },

}
