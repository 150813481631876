import {UIDConfig} from './app.uid.config';

export const AppCfg: any = {

  debug: false,
  language: 'en',
  defaultProfilePicture: '/assets/herz.png',
  portalName: 'Pflegefux GmbH Partnerportal',
  portalVersion: '1.0.10',
  availableLanguages: [
    {
      key: 'en',
      title: 'English'
    },
    {
      key: 'de',
      title: 'Deutsch'
    },
    {
      key: 'ro',
      title: 'Romana'
    },
  ],

  // endpoint: 'http://10.192.74.116:8080/restapi/api/v1',
  endpoint: 'https://carefox.at/restapi/api/v1',
  // endpointDesign: ' http://localhost:3001/',

  attributesEndpoint: {

    transportList: {
      definition: '/carefox/definitions/transports/transport',
      search: '/transportlist/transports/list',
      sendSMS: '/transportlist/transports/sms',
      update: '/transportlist/transports/{idturnus}',
    },

    pfleger: {

      create: '/caregivers/caregiver',

      definition: '/carefox/definitions/caregivers',

      query: '/pfleger/query',

      validationConfiguration: '/pfleger/validationConfiguration',

      results: '/caregivers/caregiver',

      // @ApiOperation(value = "Returns the carer information")
      profile: '/caregivers/{idcaregiver}',

      profilebyAllocation: '/caregivers/carer/{idcaregiver}',

      searchAndAssign: '/caregivers/caregiver/create',

      documents: {

        personalDocumentUpload: '/pfleger/{idpflegerstamm}/pflegerstammdoc/{begriff}',

        // @ApiOperation(value = "Returns content of a document")
        content: '/pfleger/{idpflegerstamm}/pflegerstammdoc/{begriff}/content',

        upload: '/documents/caregiver/{idcaregiver}/caregiverdocument/{term}',
        patientUpload: '/documents/care/{idallocation}/patientcaregiverdocument/{idpatient}/{term}',

        // @ApiOperation(value = "Returns a list of all existing documents with term, file name and validity.")
        structure: '/documents/terms?type=caregiver&latest=true',
        patientDocumentStructure: '/documents/terms?type=patient&latest=true',
        list: '/documents/caregivers/documents/{caregiverid}',

        // @ApiOperation(value = "Upload of a document")
        patient: '/pfleger/{idpfleger}/patientpflegerdoc/{idpatient}/{begriff}',

        caregiverDocuments: '/documents/care/documents?idallocation={idallocation}&patientid={patientid}'

      },

      agencies: {

        list: '/agencies/list',
        definition: '/carefox/definitions/allocations',
        query: '/caregivers/allocations?caregiverid={caregiverid}',

      },

      patients: {

        definition: '/carefox/definitions/patient/history',
        query: '/caregivers/patients/{idcaregiver}',

        documents: {
          definition: '/carefox/definitions/patient/caregivers/documents',
          query: '/documents/care/documents?idallocation={idallocation}&patientid={patientid}'
        }
      },

      allocations: {

        definition: '/carefox/definitions/allocations/history',
        query: '/caregivers/allocations?caregiverid={caregiverid}',
        search: '/caregivers/allocations'

      },

      pflegerabbruch: {

        definition: '/carefox/definitions/allocations/cancellations',
        query: '/care/cancellations/{idcaregiver}',

      },

      sva: {
        definition: '/carefox/definitions/caregivers/insurancepayments',
        // @ApiOperation(value = "Post request to get the data from the sva status")
        query: '/care/insurance?idcaregiver={idcaregiver}',

        svaPaymentsDefinition: '/carefox/definitions/caregivers/svapayments',
        getSvaInstallmentPayments: '/care/svainstallmentpayments/{idcaregiver}',
        saveSvaInstallmentPayment: '/care/svainstallmentpayments',
        updateSvaInstallmentPayment: '/care/svainstallmentpayments/{idsvainstallmentpayments}',
        deleteSvaInstallmentPayment: '/care/svainstallmentpayments/{idsvainstallmentpayments}'
      },

      businessStatus: {

        history: {
          query: '/business/history/{idcaregiver}',
          definition: '/carefox/definitions/business/history'
        },

        query: '/business/check/{idcaregiver}/{checkNow}',
        firmaCheck: 'https://svacheck.mooo.com/api/web/v1/check/firma',

      },

      pflegerstammvermerk: {

        definition: '/carefox/definitions/caregivers/notes/insert',
        query: '/care/notes?idcaregiver={idcaregiver}',
        delete: '/pfleger/{idpflegerstamm}/pflegerstammvermerk/{id}',

      },

      turnus: {

        history: {
          definition: '/carefox/definitions/transports/turnus',
          query: '/transportlist/turnus/history/{idcaregiver}'
        }

      },

      invoice: {
        supplier: '/invoice/supplier/{idturnus}',
        customer: '/invoice/customer/{idturnus}',
        inputStatement: '/invoice/customer/inputStatement/{idturnus}',
        outputStatement: '/invoice/turnus/outputStatement/{idturnus}',
      },

      print: {
        languagesTypes: '/print/languages/types',
        patientBill: '/print/patient/bill/{idjoposten}',
        patientDatasheet: '/print/patient/datasheet',
        contract: '/print/contract/{idpatientturnus}',
        transports: '/print/transports/pdf',
      }

    },

    allocationSuggestion: {
      definition: '/carefox/definitions/suggestion',
      historyDefinition: '/carefox/definitions/suggestion/list',
      available: '/allocation/suggestion/available/{idturnus}',
      turnus: '/allocation/suggestion/turnus/{idturnus}',
      suggest: '/allocation/suggestion/suggest/{idturnus}',
    }
  },

  carefox: {

    authentication: '/authentication',

    caregivers: {

      basePath: '/carefox',
      definition: '/definitions/caregiver',

      getAll: '/caregivers',
      getOne: '/caregivers/{id}',
      create: '/caregivers',
      update: '/caregivers/{id}',
      delete: null
    },
    cancellation: {

      basePath: '/carefox',
      definition: '/definitions/cancellation',

      getAll: '/cancellations',
      getOne: '/cancellations/{id}',
      create: '/cancellations',
      update: '/cancellations/{id}',
      delete: '/cancellations/{id}'
    },

    credentials: {

      basePath: '/carefox',
      definition: '/definitions/credentials',

      getAll: '/cancellations',
      getOne: '/cancellations/{id}',
      create: '/cancellations',
      update: '/cancellations/{id}',
      delete: '/cancellations/{id}'

    },

    insurancepayments: {

      basePath: '/carefox',
      definition: '/definitions/insurancepayments',

      getAll: '/insurancepayments',
      getOne: '/insurancepayments/{id}',
      create: '/insurancepayments',
      update: '/insurancepayments/{id}',
      delete: '/insurancepayments/{id}'

    },
    allocations: {

      basePath: '/carefox',
      definition: '/definitions/allocations',

      getAll: '/allocations',
      getOne: '/allocations/{id}',
      create: '/allocations',
      update: '/allocations/{id}',
      delete: '/allocations/{id}'

    },
    caregiverdocuments: {

      basePath: '/carefox',
      definition: '/definitions/caregiverdocuments',

      getAll: '/caregivers/documents',
      getOne: '/caregivers/documents/{id}',
      create: '/caregivers/documents',
      update: '/caregivers/documents/{id}',
      delete: '/caregivers/documents/{id}'
    },
    care: {

      basePath: '/carefox',
      definition: '/definitions/caregiverdocuments',

      getAll: '/care',

    },
    caredocuments: {

      basePath: '/carefox',
      definition: '/definitions/caredocuments',

      getAll: '/care/documents',
      getOne: '/care/documents/{id}',
      create: '/care/documents',
      update: '/care/documents/{id}',
      delete: '/care/documents/{id}'

    },
    patients: {
      basePath: '/carefox',
      definition: '/definitions/patients',

      getAll: '/patients',
      getOne: '/patients/{id}',
      create: '/patients',
      update: '/patients/{id}',

    },
    patientdocuments: {

      basePath: '/carefox',
      definition: '/definitions/patientdocuments',

      getAll: '/care/documents',
      getOne: '/care/documents/{id}',
      create: '/care/documents',
      update: '/care/documents/{id}',
      delete: '/care/documents/{id}'

    },
    documents: {

      basePath: '/',
      definition: '/definitions/documents',

      getAll: '/documents',
      getOne: '/documents/{id}/content',
      create: '/documents',
      update: '/documents/{id}',
      delete: '/documents/{id}'

    },
    terms: {

      basePath: '/',
      definition: '/definitions/terms',

      getAll: '/terms',
      getOne: '/terms/{id}',
      update: '/terms/{id}',
      delete: '/terms/{id}'

    },
  },

  /**
   *
   * UID DESIGN
   * version: 1.0
   * @constructor
   *
   */


  uid: UIDConfig,

};
