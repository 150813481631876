import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-allocation-table',
  templateUrl: './allocation-table.component.html',
  styleUrls: ['./allocation-table.component.scss']
})
export class AllocationTableComponent implements OnInit {


  //TODO: check this out, not showing the caregiver name

  data: any;
  endpoint: any;

  constructor() {}

  ngOnInit() {
  }

}
