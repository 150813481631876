import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {AppCfg} from './app.config';
import {Router} from '@angular/router';
import {AuthenticationService} from '@softline/auth';
import {ConnectionService} from '@softline/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public appPages = [
    {
      title: 'Carers',
      url: '/results',
      icon: 'medkit',
      authRequired: true
    },
    {
      title: 'Transport',
      url: '/transport',
      icon: 'car',
      authRequired: true
    },
  ];

  currentLanguage: string = null;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    public translate: TranslateService,
    public authService: AuthenticationService,
    private connectionService: ConnectionService,
  ) {
    this.initializeApp();
    this.currentLanguage = localStorage.getItem('language');
    if (!this.currentLanguage) {
      this.currentLanguage = 'de';
    }

    translate.setDefaultLang(this.currentLanguage);
  }

  ngOnInit() {
    this.connectionService.load();
    this.authService.init();
    localStorage.setItem('AppCfg', JSON.stringify(AppCfg));
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
