import {CarerSortPipe} from './pipes/object-sort.pipe';
import {NgModule} from '@angular/core';
import {DateFormatPipe} from './pipes/date.pipe';
import {KeysPipe} from './pipes/keys.pipe';
import {SecureImagePipe} from './pipes/secure-image.pipe';

@NgModule({
    declarations: [
        CarerSortPipe,
        DateFormatPipe,
        KeysPipe,
        SecureImagePipe
    ],
    imports: [],
    exports: [
        CarerSortPipe,
        DateFormatPipe,
        KeysPipe,
        SecureImagePipe
    ]
})
export class PipesModule {
}
