import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CarerGuard} from './guards/carer-guard.service';
import {AuthenticationGuard} from '@softline/auth';
import {MainLayoutComponent} from './modules/shared/components/main-layout/main-layout.component';
import {ErrorComponent} from './modules/shared/components/error/error.component';
import {SharedModule} from './modules/shared/shared.module';

const routes: Routes = [
  // Main Redirect
  {
    path: '',
    redirectTo: '/carers',
    pathMatch: 'full'
  },
  // Application
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'carers',
        loadChildren: './modules/carers/carers.module#CarersModule',
      },
      {
        path: 'transports',
        loadChildren: './modules/transports/transports.module#TransportsModule',
      },
    ]
  },
  // Account
  {
    path: 'account',
    loadChildren: './modules/account/account.module#AccountModule',
  },
  // External
  {
    path: 'external',
    component: MainLayoutComponent,
    canActivate: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        loadChildren: './modules/external/external.module#ExternalModule',
      }
    ]
  },
  // Errors
  {
    path: '**',
    component: ErrorComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'}),
    SharedModule
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
