import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'carersort'})

export class CarerSortPipe implements PipeTransform {

    transform(array: any, field: string, sort: string = 'asc'): any[] {
        if (!Array.isArray(array)) {
            return;
        }

        if (sort === 'asc') {

            array.sort((a: any, b: any) => {
                if (a[field] < b[field]) {
                    return -1;
                } else if (a[field] > b[field]) {
                    return 1;
                } else {
                    return 0;
                }
            });

        } else {

            array.sort((a: any, b: any) => {
                if (a[field] > b[field]) {
                    return -1;
                } else if (a[field] < b[field]) {
                    return 1;
                } else {
                    return 0;
                }
            });

        }


        return array;
    }

}
