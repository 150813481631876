import {Injectable} from '@angular/core';
import {AppCfg} from '../../app.config';

@Injectable({
    providedIn: 'root'
})
export class ConsoleService {

    private error: any = 'color: red;';
    private success: any = 'color: green;';
    private warning: any = 'color: orange;';
    private info: any = 'color: #ff8800;';

    constructor() {
    }

    /**
     * An internal console log implementation for debugging and tracking console log
     * @param type
     * @param string
     * @param data
     */
    setMessage(type: any, string: string, data: any = null) {

        if (!AppCfg.debug) { return; }
        if (type === 'success') { type = this.success; }
        if (type === 'warning') { type = this.warning; }
        if (type === 'error') { type = this.error; }
        if (type === 'info') { type = this.info; }
        console.log('%c ' + string + '', type);
    }


}
