import {RouteReuseStrategy} from '@angular/router/';
import {ActivatedRouteSnapshot, DetachedRouteHandle} from '@angular/router';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  storedRouteHandles = new Map<string, DetachedRouteHandle>();
  allowRetrieveCache = {
    '/carers': true
  };

  shouldReuseRoute(before: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const fromUrls = [
      '/carers/create',
      '/carers/:id/update',
    ];

    this.allowRetrieveCache['/carers'] = fromUrls.indexOf(this.getPath(before)) !== -1 && this.getPath(curr) === '/carers';
    return before.routeConfig === curr.routeConfig;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (!route.routeConfig) {
      return null;
    }
    if (route.routeConfig.loadChildren) {
      return null;
    }
    return this.storedRouteHandles.get(this.getPath(route)) as DetachedRouteHandle;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getPath(route);
    if (this.allowRetrieveCache[path]) {
      return this.storedRouteHandles.has(this.getPath(route));
    }

    return false;
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    if (!route.routeConfig || route.routeConfig.loadChildren) {
      return false;
    }
    const path = this.getPath(route);
    if (this.allowRetrieveCache.hasOwnProperty(path)) {
      return true;
    }
    return false;
  }

  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
    this.storedRouteHandles.set(this.getPath(route), detachedTree);
  }

  /**
   * Gets the URL path info.
   *
   * @param route
   * @return String
   */
  private getPath(route: ActivatedRouteSnapshot): string {
    const segments = [];
    route.pathFromRoot.forEach((value) => {
      if (value.routeConfig !== null && value.routeConfig.path !== null) {
        segments.push(value.routeConfig.path);
      }
    });

    let path = segments.join('/');
    if (path.endsWith('/')) {
      path = path.slice(0, -1);
    }

    return path;
  }
}
