import {NgModule} from '@angular/core';
import {CarerDocumentsPage} from './carerdocuments.page';
import {MaterialModule} from '../../material.module';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgxFileDropModule} from 'ngx-file-drop';
import { SoftlineComponentsModule } from '@softline/shared';
import {NgxDocViewerModule} from 'ngx-doc-viewer';

@NgModule({
    declarations: [CarerDocumentsPage],
    exports: [CarerDocumentsPage],
    entryComponents: [CarerDocumentsPage],
    imports: [
        CommonModule,
        FormsModule,
        SoftlineComponentsModule,
        IonicModule,
        MaterialModule,
        TranslateModule,
        NgxFileDropModule,
        NgxDocViewerModule,
        ReactiveFormsModule
    ]
})

export class CarerdDcumentsModule {
}
